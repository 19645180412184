<template>
<v-app class="app">
    <div class="theme">
        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="indigo darken-1" small fab @click="darkMode">
                    <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
                </v-btn>
            </template>
            <span>Dark Mode On</span>
        </v-tooltip>

        <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="indigo darken-1" small fab @click="darkMode">
                    <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                </v-btn>
            </template>
            <span>Dark Mode Off</span>
        </v-tooltip>
    </div>
    <div class="top-bar">
        <h1>Do you own that token? 💰</h1>
        <div class="dynamic-top">
            <div v-if=is_connected>
                <div>
                    <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small color="pink lighten-3">Curious about someone else address? 👀</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <v-text-field v-model="tmp_address" rounded label="Address or ENS" v-on:keyup.enter="searchAddress"></v-text-field>
                            </v-card-title>
                            
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text single-line v-on:click="searchAddress">
                                    Search 🔎
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <p style="margin-top:10px"><b>📢 Address/ENS:</b> {{address}}</p>
                    <h4 style="margin-bottom:15px">🎉 Is the proud owner of ...</h4>
                </div>
                <v-card style="margin-bottom:75px" class="mx-auto" max-width="700" tile>
                    <v-list outlined rounded>
                        <v-list-item v-for="(item, i) in token_balances" :key="i">
                            <v-list-item-avatar>
                                <v-img contain :src="item.icon"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content style="text-align: left;">
                                <v-list-item-title>({{item.symbol}}) {{item.name}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-title v-text="item.balance"></v-list-item-title>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn icon v-on:click="addTokenToMetamask(item)">
                                    <v-img max-height="30" max-width="30" src="https://static.coingecko.com/s/metamask_fox-11b1aab7f9a07cbe8903d8d6eb1e6d42be66d1bdd838c10786c1c49a2efb36f0.svg"></v-img>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
            <div v-else>
                <h4 style="margin-bottom:20px">🔎 Find which token you actually have...</h4>
                <v-btn color="indigo darken-1" raised height="70" rounded large class="btn" v-on:click="connect">
                    <v-img max-height="150" max-width="250" src="https://raw.githubusercontent.com/MetaMask/brand-resources/c3c894bb8c460a2e9f47c07f6ef32e234190a7aa/SVG/metamask-fox-wordmark-horizontal.svg"></v-img>
                </v-btn>
            </div>
        </div>
    </div>
    <dir class="bottom-center">
        <p>by <a target="_blank" href="https://twitter.com/marcoporracin">@marcoporracin</a></p>
    </dir>
    <div v-if="is_connected" class="bottom-bar" v-on:click="buyMeACoffe">
        <v-btn color="green lighten-2" rounded>☕ Buy me a coffe!</v-btn>
    </div>

</v-app>
</template>

<script>
import {
    ethers
} from "ethers";
import ERC20abi from '@/ERC20abi.json'
import OTokens from '@/other_tokens.json'

export default {
    name: 'App',
    data: () => ({
        is_connected: false,
        ethereum: {},
        provider: {},
        address: '',
        token_balances: [],
        eth_balance: 0,
        token_info: {},
        dialog: false,
        tmp_address: '',
    }),
    mounted() {
        this.darkMode()
        this.fetch_token_info()
        this.initSetup()
    },
    watch: {
        address: function () {
            this.getTokenBalances();
        }
    },
    methods: {
        initSetup() {
            this.ethereum = window.ethereum;
            this.provider = new ethers.providers.Web3Provider(this.ethereum)
            this.signer = this.provider.getSigner()
        },
        searchAddress() {
            this.address = this.tmp_address
            this.dialog = false
        },
        askForAddress() {
            this.dialog = true
        },
        async buyMeACoffe() {
            const transactionParameters = {
                to: '0x6Adf7dC623F6433a62E65f6b3a8190D0677CF457',
                from: this.ethereum.selectedAddress,
                value: ethers.utils.hexlify(ethers.utils.parseEther('0.005')),
            };
            await this.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        },
        darkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
        fetch_token_info() {
            fetch("https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/tokenlist.json").then(response => {
                response.json().then(
                    data => {
                        this.token_info = data
                        this.token_info.tokens = this.token_info.tokens.concat(OTokens)
                    }
                )
            })
        },
        connect() {
            this.ethereum.request({
                method: 'eth_requestAccounts'
            }).then(
                (accounts) => {
                    this.is_connected = true
                    this.address = accounts[0];
                }
            )
        },
        getEthBalance() {
            this.provider.getBalance(this.address).then(
                (balance) => {
                    this.token_balances.push({
                        icon: "https://ethereum.org/static/bfc04ac72981166c740b189463e1f74c/a7715/eth-diamond-black-white.jpg",
                        symbol: 'ETH',
                        name: 'Etherum',
                        balance: ethers.utils.formatEther(balance)
                    })
                }
            )
        },
        getTokenBalances() {
            this.token_balances = [];
            this.getEthBalance();
            for (let i = 0; i < this.token_info.tokens.length; i++) {
                var token = this.token_info.tokens[i];
                if (token.type == "ERC20") {
                    this.getTokenBalance(token)
                }
            }
        },
        getTokenBalance(token) {
            new ethers.Contract(token.address, ERC20abi, this.provider).balanceOf(this.address).then(
                (balance) => {
                    if (balance > 0) {
                        this.token_balances.push({
                            address: token.address,
                            icon: token.logoURI,
                            symbol: token.symbol,
                            name: token.name,
                            decimals: token.decimals,
                            balance: ethers.utils.formatUnits(balance, token.decimals)
                        });
                    }
                })
        },
        addTokenToMetamask(token) {
            this.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: token.address,
                        symbol: token.symbol,
                        decimals: token.decimals,
                        image: token.icon,
                    },
                },
            });
        },
    }
};
</script>

<style>
.app {
    font-family: nunito, helvetica, Arial, sans-serif;
}

.top-bar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    text-align: center;
}

.dynamic-top {
    margin-top: 20px;
}

.theme {
    margin: 20px;
    position: absolute;
    right: 0;
}

.bottom-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 20px;
}

.bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>
